<template>
  <div class="clebex-cropper-wrapper" @dragenter="showInput">
    <input
      :class="fileInputClass"
      ref="fileInput"
      type="file"
      accept="image/*"
      @input="pickFile"
      @drop="pickFile"
      @dragleave="hideInput"
    />
    <cropper
      v-if="previewPhoto || image"
      ref="cropper"
      class="cropper"
      :src="previewPhoto ? previewPhoto : image"
      imageRestriction="stencil"
      :autoZoom="true"
      :minWidth="100"
      :minHeight="100"
      :stencil-component="$options.components.RectangleStencil"
    ></cropper>
    <div class="clebex-profile-photo-drag-drop-label">
      {{ displayLabelName("global.image-selection.drag-and-drop") }}
    </div>
  </div>
  <div class="clebex-photo-selection-type-wrapper">
    <div
      v-if="!mobileDevice"
      class="clebex-photo-selection-type clebex-take-photo"
      @click="initCamera"
    >
      <span>
        {{ displayLabelName("profile", "profile", "take-photo") }}
      </span>
      <div>
        <icon icon="#cx-hea1-camera" />
      </div>
    </div>
    <div
      :class="
        `clebex-photo-selection-type clebex-choose-existing-photo ${
          mobileDevice ? 'mobile' : ''
        } ${!previewPhoto && image ? 'has-photo' : ''}`
      "
      @click="selectImage"
    >
      <span>
        {{
          mobileDevice
            ? displayLabelName("profile", "profile", "new-photo")
            : displayLabelName("profile", "profile", "choose-existing-photo")
        }}
      </span>
      <div>
        <icon icon="#cx-hea1-camera" />
      </div>
    </div>
    <div
      v-if="!previewPhoto && image"
      class="clebex-photo-selection-type clebex-delete-existing-photo"
      @click="deletePhoto().finally(() => cancelPhotoUpload())"
    >
      <span>
        {{ displayLabelName("profile", "profile", "delete-photo") }}
      </span>
      <div>
        <icon icon="#cx-foo1-delete-01-28x28" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RectangleStencil, Cropper } from "vue-advanced-cropper";
import { getScreenId } from "@/services/helpers";

export default {
  name: "ImageSelection",
  components: {
    Cropper,
    // eslint-disable-next-line vue/no-unused-components
    RectangleStencil,
  },
  data() {
    return {
      previewPhoto: null,
      fileToUpload: null,
      showFileInput: false,
      mobileDevice: false,
    };
  },
  watch: {
    photoImageUrl: {
      immediate: false,
      handler(value) {
        if (value !== null) {
          this.previewPhoto = value;
          this.$store.commit("camera/setPhotoImageUrl", null, {
            root: true,
          });
        }
      },
    },
  },
  computed: {
    ...mapGetters("camera", ["photoImageUrl"]),
    fileInputClass() {
      return `clebex-photo-file-input ${this.showFileInput ? "show" : ""}`;
    },
    store() {
      return this.storeInUse;
    },
  },
  mounted() {
    this.mobileDevice = this.isMobileDevice();
  },
  methods: {
    adjustStencil() {
      const cropperElement = this.$refs.cropper.$el;
      const imageElement = cropperElement.querySelector(
        ".vue-advanced-cropper__image"
      );

      if (imageElement) {
        const imageWidth = imageElement.width;
        const imageHeight = imageElement.height;

        console.log("Image dimensions:", imageWidth, "x", imageHeight);

        // Calculate the desired stencil size and coordinates based on the image dimensions
        const stencilWidth = imageWidth / 2; // Adjust as needed
        const stencilHeight = imageHeight / 2; // Adjust as needed
        const stencilX = (imageWidth - stencilWidth) / 2;
        const stencilY = (imageHeight - stencilHeight) / 2;

        console.log("Stencil size:", stencilWidth, "x", stencilHeight);
        console.log("Stencil coordinates:", stencilX, ",", stencilY);
        const cropper = this.$refs.cropper;
        cropper.stencilCoordinates.width = 50;
        cropper.stencilCoordinates.height = stencilHeight;
        console.log(cropper.stencilCoordinates);

        // // Set the stencil size and coordinates
        // const cropper = this.$refs.cropper;
        // cropper.setStencilSize(stencilWidth, stencilHeight);
        // cropper.setStencilCoordinates(stencilX, stencilY);
      }
    },
    showInput() {
      this.showFileInput = true;
    },
    hideInput() {
      this.showFileInput = false;
    },
    selectImage() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      let input = this.$refs.fileInput;
      let file = input.files;
      if (file && file[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.previewPhoto = e.target.result;
        };
        reader.readAsDataURL(file[0]);
        this.fileToUpload = file[0];
      }
      this.hideInput();
    },
    cancelPhotoUpload() {
      this.previewPhoto = null;
      this.fileToUpload = null;
      this.$refs.fileInput.value = "";
    },
    getRoundedCanvas(sourceCanvas, selectionWidth, selectionHeight) {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      var width = selectionWidth;
      var height = selectionHeight;

      canvas.width = width;
      canvas.height = height;
      context.imageSmoothingEnabled = true;
      context.drawImage(sourceCanvas, 0, 0, width, height);
      context.globalCompositeOperation = "destination-in";
      context.fill();
      return canvas;
    },
    cropImage() {
      const result = this.$refs.cropper.getResult();
      console.log(result);
      const selectionWidth = result.coordinates.width;
      const selectionHeight = result.coordinates.height;
      const squareCanvas = result.canvas;
      const roundCanvas = this.getRoundedCanvas(
        squareCanvas,
        selectionWidth,
        selectionHeight
      );
      roundCanvas.toBlob(
        (blob) => this.uploadPhoto(blob).then(() => this.cancelPhotoUpload),
        "image/png",
        1
      );
    },
    initCamera() {
      this.$store.commit("camera/setCameraActive", true, { root: true });
      this.$store.commit(
        "camera/setCameraScreenId",
        getScreenId(this.findElement()),
        {
          root: true,
        }
      );
    },
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    uploadPhoto: {
      type: Function,
      required: true,
    },
    deletePhoto: {
      type: Function,
      required: true,
    },
  },
};
</script>
